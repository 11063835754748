import { configureStore } from '@reduxjs/toolkit';

import accountSlice from "./account/slice";
import adminSlice from "./admin/slice";
import authSlice from './auth/slice';
import electricalReadingSlice from "./eletricalreading/slice";
import fatReadingSlice from "./fatreading/slice";
import measurementSlice from "./measurement/slice";
import nodeSlice from "./node/slice";
import projectSlice from "./project/slice";
import publicSlice from "./public/slice";
import settingsSlice from "./settings/slice"
import componentSlice from "./component/slice";
import settingsGridSlice from "./settingsGrid/slice";

export * from './account/slice';
export * from './admin/slice';
export * from './auth/slice';
export * from './fatreading/slice';
export * from './eletricalreading/slice'
export * from './measurement/slice';
export * from './node/slice';
export * from './project/slice';
export * from './public/slice';
export * from './settings/slice';
export * from './settingsGrid/slice';
export * from './component/slice';

export const store = configureStore({
    reducer: {
        account: accountSlice.reducer,
        admin: adminSlice.reducer,
        auth: authSlice.reducer,
        fatReading: fatReadingSlice.reducer,
        electricalReading: electricalReadingSlice.reducer,
        measurements: measurementSlice.reducer,
        node: nodeSlice.reducer,
        projects: projectSlice.reducer,
        public: publicSlice.reducer,
        settings: settingsSlice.reducer,
        settingsGrid: settingsGridSlice.reducer,
        components: componentSlice.reducer,
    },
});
