import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper } from "../../helpers";
import {
  API_ACCOUNT
} from "../../constants/network";

// Create Slice:
const name = "settings";
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const settingsSlice = createSlice({ name, initialState, extraReducers });

// Exports:
export const settingsActions = { ...settingsSlice.actions, ...extraActions };

// Implementation:
function createInitialState() {
  return {
    menuHidden: false,
    darkTheme: false
  }
}

function createExtraActions() {
  function settingsGet() {
    return createAsyncThunk(
        "/settings/get",
        async () =>
            await fetchWrapper.get(
                `${API_ACCOUNT}/settings`,
                null
            )
    );
  }

  function settingsUpdate() {
    return createAsyncThunk(
      "/settings/update",
      async ({ body }) =>
        await fetchWrapper.put(`${API_ACCOUNT}/settings`, body)
    );
  }
  return {
    settingsGet: settingsGet(),
    settingsUpdate: settingsUpdate()
  };
}

function createExtraReducers() {
  function settingsGet() {
    let { pending, fulfilled, rejected } = extraActions.settingsGet;

    return {
      [pending]: (state) => {
        state.error = null;
      },
      [fulfilled]: (state, action) => {
        state = action.payload;
        return state;
      },
      [rejected]: (state, action) => {
        state.error = action.error;
      },
    };
  }

  function settingsUpdate() {
    let { pending, fulfilled, rejected } = extraActions.settingsUpdate;

    return {
      [pending]: (state) => {
        state.error = null;
      },
      [fulfilled]: (state, action) => {
        state = action.payload;
        return state;
      },
      [rejected]: (state, action) => {
        state.error = action.error;
      },
    };
  }

  return {
    ...settingsGet(),
    ...settingsUpdate()
  };
}

export default settingsSlice;
