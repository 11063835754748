import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper } from "../../helpers";
import {
  API_ACCOUNT
} from "../../constants/network";

// Create Slice:
const name = "settingsGrid";
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const settingsGridSlice = createSlice({ name, initialState, extraReducers });

// Exports:
export const settingsGridActions = { ...settingsGridSlice.actions, ...extraActions };

// Implementation:
function createInitialState() {
  return {
  }
}

function getURLByGridType(gridType) {
  let url = `${API_ACCOUNT}/settings/grids`;
  switch (gridType) {
    case "M":
      url = url + "/MODEL";
      break;
    case "T":
      url = url + "/TAIL";
      break;
    case "S":
      url = url + "/SYSTEM";
      break;
    case "U":
      url = url + "/SUBSYSTEM";
      break;
    case "C":
      url = url + "/COMPONENT";
      break;
    default:
      url = url + "/" + gridType;
      break;
  }
  return url;
}


function createExtraActions() {

  function settingGridGet() {
    return createAsyncThunk(
        "/settings/grid/get",
        async ({ gridType }) =>
            await fetchWrapper.get(getURLByGridType(gridType), undefined)
    );
  }

  function settingGridPut() {
    return createAsyncThunk(
        "/settings/grid/put",
        async ({ gridType, model }) => {
          await fetchWrapper.put(getURLByGridType(gridType), model)
        }
    );
  }

  return {
    settingsGridGet: settingGridGet(),
    settingsGridPut: settingGridPut()
  };
}

function createExtraReducers() {

  function settingGridGet() {
    let { pending, fulfilled, rejected } = extraActions.settingsGridGet;

    return {
      [pending]: (state) => {
        state.error = null;
      },
      [fulfilled]: (state, action) => {
        return action.payload;
      },
      [rejected]: (state, action) => {
        state.error = action.error;
      },
    };
  }

  function settingGridPut() {
    let { pending, fulfilled, rejected } = extraActions.settingsGridPut;

    return {
      [pending]: (state) => {
        state.error = null;
      },
      [fulfilled]: (state, action) => {
        return action.payload;
      },
      [rejected]: (state, action) => {
        console.log("Rejected", state, action);
      },
    };
  }


  return {
    ...settingGridGet(),
    ...settingGridPut()
  };
}

export default settingsGridSlice;