import * as React from 'react';
import {useEffect} from "react";
import GenTWFPlot from "../vibration_general/GenTWFPlot";
import GenFFTPlot from "../vibration_general/GenFFTPlot";
import GenFFTvelPlot from "../vibration_general/GenFFTvelPlot";
import GenComparePlot from "../vibration_general/GenComparePlot";
import FuelBootsPumpTabPanel from "./FuelBoostPumpTabPanel";

export default function FuelBoostPumpComponent({ isDashboard, nodeId, projectName, graphType, setGraphType }) {
    const [tabId, setTabId] = React.useState(0);
    const [collectionTime, setCollectionTime] = React.useState(null);

    useEffect(() => {
    }, [collectionTime, graphType]);

    if (!graphType) {
        return (
            <FuelBootsPumpTabPanel
                isDashboard={isDashboard}
                nodeId={nodeId}
                projectName={projectName}
                tabId={tabId}
                setTabId={setTabId}
                setGraphType={setGraphType}
                collectionTime={collectionTime}
                setCollectionTime={setCollectionTime}
            />
        )
    } if (graphType === 'plot') {
        return (
            <GenTWFPlot
                nodeId={nodeId}
                projectName={projectName}
                collectionTime={collectionTime}
                setCollectionTime={setCollectionTime}
                setGraphType={setGraphType}
            />
        )
    } else if (graphType === 'fft') {
        return (
            <GenFFTPlot
                nodeId={nodeId}
                projectName={projectName}
                collectionTime={collectionTime}
                setCollectionTime={setCollectionTime}
                setGraphType={setGraphType}
            />
        )
    } else if (graphType === 'fftvel') {
        return (
            <GenFFTvelPlot
                nodeId={nodeId}
                projectName={projectName}
                collectionTime={collectionTime}
                setCollectionTime={setCollectionTime}
                setGraphType={setGraphType}
            />
        )
    } else if (graphType === 'compare') {
        return (
            <GenComparePlot
                nodeId={nodeId}
                projectName={projectName}
                collectionTime={collectionTime}
                setCollectionTime={setCollectionTime}
                setGraphType={setGraphType}
            />
        )
    }
}
