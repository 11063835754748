import * as React from 'react';
import Box from '@mui/material/Box';
import {DataGrid, GridToolbar} from '@mui/x-data-grid';
import Moment from 'moment';
import {useEffect, useState} from "react";
import {fatReadingActions} from "../../store";
import {useDispatch} from "react-redux";
import {Button} from "@mui/material";
import {authHeader} from "../../helpers";
import {API_FAT_READING_PAGE} from "../../constants/network";

export default function TEFGrid({ projectName, nodeId}) {
    const dispatch = useDispatch();
    const [gridData, setGridData] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [rowCount, setRowCount] = useState(0);
    const [sort, setSort] = useState("collectionTime,desc");
    const [page, setPage] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const handleClickDownloadButton = (event, row) => {
        event.stopPropagation();

        const url = `${API_FAT_READING_PAGE}/${projectName}/${nodeId.substring(2)}/download/${row.id}`;
        const headers = authHeader(url);
        fetch(url, {
            method: 'get',
            headers: headers
        }).then(response => {
            return response.blob();
        }).then(blob => {
            window.open(URL.createObjectURL(blob), '_blank');
        });
    };

    const handleSortModelChange = (sortModel) => {
        if (sortModel && sortModel.length > 0) {
            const orderBy = sortModel[0].field;
            const order = sortModel[0].sort;
            setSort(orderBy + "," + order);
        }
    };

    const formatDec = {
        pattern: '0.00',
    };

    const columns = [
        {field: 'id', headerName: 'ID', width: 60, hide: true},
        {
            field: 'collectionTime',
            headerName: 'Timestamp',
            width: 190,
            editable: false,
            align:'center',
            headerAlign: "center",

            valueFormatter: params =>  params.value ? new Moment(params.value).utc(false).format('L')  + " " + new Moment(params.value).utc(false).format('LTS') : "",
        },
        {
            field: 'deltaV1_8',
            headerName: '∆ V1-8',
            type: 'number',
            numericFormat: formatDec,
            align:'right',
            headerAlign: "center",
            width: 140,
            editable: false,
            valueGetter: (row) => {
                return row.value * 1000;
            },
        },
        {
            field: 'deltaV2_7',
            headerName: '∆ V2-7',
            type: 'number',
            numericFormat: formatDec,
            align: 'right',
            headerAlign: "center",
            width: 140,
            editable: false,
            valueGetter: (row) => {
                return row.value * 1000;
            }
        },
        {
            field: 'deltaV3_6',
            headerName: '∆ V3-6',
            type: 'number',
            numericFormat: formatDec,
            align:'right',
            headerAlign: "center",
            width: 140,
            editable: false,
            valueGetter: (row) => {
                return row.value * 1000;
            }
        },
        {
            field: 'deltaV4_5',
            headerName: '∆ V4-5',
            type: 'number',
            numericFormat: formatDec,
            align:'right',
            headerAlign: "center",
            width: 140,
            editable: false,
            valueGetter: (row) => {
                return row.value * 1000;
            }
        },
        {
            field: 'deltaPt',
            headerName: '∆ Pt',
            type: 'number',
            numericFormat: formatDec,
            align:'right',
            headerAlign: "center",
            width: 140,
            editable: false,
            valueGetter: (row) => {
                return row.value * 1000;
            }
        },
        {
            field: 'deltaInd',
            headerName: '∆ Ind',
            type: 'number',
            numericFormat: formatDec,
            align:'right',
            headerAlign: "center",
            width: 140,
            editable: false,
            valueGetter: (row) => {
                return row.value * 1000;
            }
        },
        { field: 'plot', headerName: '',sortable: false, width: 100, renderCell: (params) => {
                return (
                    <Button
                        onClick={(e) => handleClickDownloadButton(e, params.row)}
                        variant="contained"
                        style={{
                            backgroundColor: "#4169E1",
                            fontSize: "12px",
                            margin: "4px",
                            alignItems: "center"
                        }}
                    >
                        Download
                    </Button>
                );
            }
        },

    ];

    useEffect(() => {
        if (nodeId) {
            if (nodeId.substring(0, 2) === "C-") {
                setIsLoading(true);
                let pageable = { size: pageSize, page, sort };
                projectName && dispatch(fatReadingActions.getFatReadingPage({
                    projectName: projectName,
                    componentId: nodeId.substring(2),
                    pageable
                }))
                    .then((state) => {
                        if (state && state.payload) {
                            setIsLoading(false);
                            setGridData(state.payload.content);
                            setRowCount(state.payload.totalElements);
                        } else {
                            setGridData([]);
                            setRowCount(0);
                        }
                        setIsLoading(false);
                    });
            } else {
                setGridData([]);
                setIsLoading(false);
            }
        }
    }, [dispatch, nodeId, projectName]);

    return (
        <div>
            <Box height="69vh" m="-10px 0 0 0">
                <DataGrid
                    loading={isLoading}
                    rows={gridData}
                    columns={columns}
                    onSortModelChange={handleSortModelChange}
                    sortingMode="server"
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    pagination
                    paginationMode="server"
                    pageSize={pageSize}
                    rowCount={rowCount}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: {pageSize},
                            },
                        },
                        sorting: {
                            sortModel: [{ field: 'collectionTime', sort: 'desc' }],
                        },
                    }}

                    getCellClassName={(params) => {
                        if (params.field === 'deltaV1_8' ||
                            params.field === 'deltaV2_7' ||
                            params.field === 'deltaV3_6' ||
                            params.field === 'deltaV4_5' ||
                            params.field === 'deltaPt' ||
                            params.field === 'deltaInd') {
                            if (params.value > 250) {
                                return 'danger';
                            } else if (params.value > 170) {
                                return 'warning';
                            } else {
                                return 'normal';
                            }
                        }
                    }}

                    sx={{
                        '& .normal': {
                        },
                        '& .warning': {
                            backgroundColor: '#cccc0022',
                        },
                        '& .danger': {
                            backgroundColor: '#ff000022',
                        },
                    }}

                    components={{ Toolbar: GridToolbar }}
                    checkboxSelection
                    disableRowSelectionOnClick
                />
            </Box>
        </div>
    );
}
